import React from 'react';
import "./App.scss";
import ReactGA from "react-ga"
// import './assets/scss/style.scss';
// import './assets/keenicons/outline/style.css';
// import './assets/keenicons/duotone/style.css';
// import './assets/keenicons/solid/style.css';
import Header from "./components/Header";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import CatalogView from "./views/CatalogView";
import AboutView from "./views/AboutView";
import BlogView from "./views/BlogView";
import BlogPostView from "./views/BlogPostView";
import ServiceView from "./views/ServiceView";
import ServiceInfo from "./views/ServiceView/components/ServiceInfo";
import Footer from "./components/Footer/Footer";
import HomeView from './views/HomeView';

ReactGA.initialize("G-4ZKQ69QWR1");

function App() {
    return (
        <div className=''>
            <div className="d-flex flex-column flex-root">
                <BrowserRouter basename="/">
                    <div className="page d-flex flex-column flex-column-fluid">
                        <Header />
                        <Routes>
                            <Route path="/" element={<HomeView />} />
                            <Route path="/catalog" element={<CatalogView />} />
                            <Route path="/catalog/:serviceId" element={<ServiceView />}>
                                <Route path="" element={<ServiceInfo />} />
                                <Route path="screens" element={<ServiceView />} />
                            </Route>
                            {/*<Route path="/blog" element={<BlogView/>}/>*/}
                            {/*<Route path="/blog/:postId" element={<BlogPostView/>}/>*/}
                            <Route path="/about" element={<AboutView />} />
                        </Routes>
                    </div>
                    <Footer />
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;
