import React, { useState } from 'react'
import RatingComponent from '../../../components/RatingComponent'
import { Service } from '../../../api/types'
import { Link, useNavigate } from 'react-router-dom'
import "../styles/serviceInfoHeader.scss"
import arrow from "../../../assets/imgs/template/company-site-button-arrow.svg"
import ISOIconComponent from '../../../components/ISOIconComponent'

export const ServiceInfoHeader = ({ service }: { service: Service }) => {

  const [readMoreBio, setReadMoreBio] = useState(false)

  return (
    <div className="service-header-main">
      <div className="left-side">
        <div className="logo">
          <img src={service.logo} alt="logo" />
        </div>
        <div className="company-site-btn">
          <Link to={service.link}>
            <button className='cta-01' >
              <span>
                Company Site
              </span>
              <img src={arrow} />
            </button>
          </Link>
        </div>
      </div>

      <div className="right-side">
        <div className='company-name'>
          {service.name}
          <div className="company-country">
            {service.countries[0].name}
          </div>
        </div>
        <div className="subheader">
          <div className="categories">
            <p className="category">
              {service.categories.map(c => (
                <span key={c.id}>{c.name}</span>))}</p>
          </div>
          <div className="ratings">
            {service.ratings.length > 0 &&
              <RatingComponent rating={service.ratings[0]} />}
          </div>
        </div>
        <div className="divider"></div>
        <div className="company-description">
          {service.description}
        </div>
        <div className="company-categories">
          <p className="category">
            {service.categories.map(c => (
              <span key={c.id}>{c.name}</span>))}</p>
        </div>
        <div className="company-certificates">
          {service.certificates.map((c, i) => (
            <div key={i} className="certificate">
              <ISOIconComponent cert={c} idx={i} />
            </div>
          ))}
        </div>
        <div className="company-self-description">
          <span>How the company describes itself</span>
          <div className="company-self-description-bio">
            {readMoreBio ?
              <>{service.bio} <a href={"#"} onClick={() => setReadMoreBio(!readMoreBio)}>Collapse</a></>
              :
              (<>{service.bio.slice(0, 100)}... <a href={"#"} onClick={() => setReadMoreBio(true)}>Read more</a></>)}
          </div>
        </div>
      </div>
    </div>
  )
}
