import { Category } from "../../../api/types";
import Loader from "../../../components/Loader";
import { CategoryBlockProps } from "./CategoryBlock";
import "../styles/categories.scss"

export type CategoriesProps = {
    menuLinkClass: string,
    onCategoryChange: (categoryId: string | null) => void,
    allActiveCLass: string,
    category: Category,
    selectedCategory: string | null
}

export const Categories = ({ menuLinkClass, onCategoryChange, allActiveCLass, category, selectedCategory }: CategoriesProps) => {
    return (
        <div className="category-container">
            <li className={menuLinkClass + " " + "category-item"}
                onClick={e => {
                    if (selectedCategory == category.id.toString()) {
                        onCategoryChange(null);
                    } else {
                        onCategoryChange(category.id.toString());
                    }
                }}>
                <div className={"categories-list-nav-item "}>
                    <div className='round'><div className={"inner-round " + menuLinkClass + " " + allActiveCLass}></div></div>
                    {category.name} <div className="categories-list-nav-item-number">({category.count})</div>
                </div>
            </li>
        </div>
    )
}
