import React, { useEffect, useState } from 'react';
import CatalogItem from "./CatalogItem/CatalogItem";
import { PageStat, Paginated, Service } from "../../../api/types";
import { getClient } from "../../../api/client";
import Loader from "../../../components/Loader";
import "../catalog.scss"

type Props = {
    selectedTagIds: number[]
    categoryId: string | null
    page: number
    setPageStat: (ps: PageStat) => void
}
const CatalogList = (props: Props) => {
    const [services, setServices] = useState<Paginated<Service>>();
    // const [page, setPage] = useState<number>(1);

    const loadServices = (page: number) => {
        setServices(undefined);
        let filters: any = { tags: props.selectedTagIds, page: page };
        if (props.categoryId) {
            filters['categories'] = props.categoryId;
        }
        getClient().catalog.services(filters).then(setServices);
    }

    // useEffect(() => {
    // setPage(1);
    // }, [props.selectedTagIds]);

    useEffect(() => {
        loadServices(props.page);
    }, [props.page, props.selectedTagIds, props.categoryId]);

    if (!services) {
        return <Loader />
    }
    props.setPageStat(services)


    return (
        <div className="row">
            <div className="box-filters mt-0 pb-5 mb-20">
                <div className="row">
                    <div className="col-xl-2 col-lg-3 mb-10 text-lg-start text-center">
                    </div>
                    <div className="col-xl-10 col-lg-9 mb-10 text-lg-end text-center">
                        {/* <span className="font-sm color-gray-900 font-medium border-1-right span">
                            Showing {services.per_page * (services.page - 1) + 1}–{services.per_page * services.page} of {services.count} results</span> */}

                        {/* <div className="d-inline-block"><span className="font-sm color-gray-500 font-medium">Show</span>
                            <div className="dropdown dropdown-sort border-1-right">
                                <button className="btn dropdown-toggle font-sm color-gray-900 font-medium"
                                        id="dropdownSort2" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                        data-bs-display="static"><span>30 items</span></button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownSort2">
                                    <li><a className="dropdown-item active" href="#">30 items</a></li>
                                    <li><a className="dropdown-item" href="#">50 items</a></li>
                                    <li><a className="dropdown-item" href="#">100 items</a></li>
                                </ul>
                            </div>
                        </div> */}
                        {/*<div className="d-inline-block">*/}
                        {/*<a className="view-type-grid mr-5 active" href="shop-vendor-list.html"></a>*/}
                        {/*<a className="view-type-list" href="#"></a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className="row catalog-list">
                {
                    services.results.map((item: Service) => {
                        return (
                            <CatalogItem key={item.id} service={item} />
                        )
                    })
                }
            </div>
        </div>
    );
};

export default CatalogList;