import React, { ReactNode } from 'react';
import { Certificates, Service } from "../../../../api/types";
import { Link } from "react-router-dom";
import RatingComponent from "../../../../components/RatingComponent";
import { Tooltip } from 'react-tooltip'
import ISOIconComponent from "../../../../components/ISOIconComponent";
import "./styles/catalogItem.scss"
import { CatalogItemHeader } from './components/CatalogItemHeader';
import { CatalogItemSubHeader } from './components/CatalogItemSubHeader';
import { CatalogItemLogo } from './components/CatalogItemLogo';
import { CatalogItemDescription } from './components/CatalogItemDescription';


// const iso = (cert: Certificates, idx: number): ReactNode => {
//     let element = <span>{cert.specification}</span>
//     if (cert.image !== null) {
//         element = <img src={cert.image} height={25} alt={cert.description}/>
//     } else if (cert.organisation_entity.image !== null) {
//         element = <img src={cert.organisation_entity.image} height={25} alt={cert.description}/>
//     } else if (cert.specification == "No ISO") {
//         element = <span></span>
//     }
//
//     let tooltipId = "cert" + idx + "id" + cert.id
//     return (<span data-tooltip-id={tooltipId} data-tooltip-content={cert.description}>
//         {element}
//         <Tooltip id={tooltipId} style={{ maxWidth: 350 }}/>
//     </span>)
// }
//
type Props = {
    service: Service,
}
const CatalogItem = (props: Props) => {
    return (
        <>
            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 catalog-item">
                <Link to={`/catalog/${props.service.id}`}>
                    <div className="card-vendor">
                        <div className="card-top-vendor" style={{ height: 100 }}>
                            <div className="card-top-vendor-left" style={{}}>
                                <img src={props.service.logo} alt="" />
                                <br />
                                <a className="color-brand-3 font-sm-bold" href="#" style={{ whiteSpace: "nowrap" }}>
                                    {props.service.name}
                                </a>
                                <br />
                                <span className="font-xs color-gray-500 mb-10" style={{ whiteSpace: "nowrap" }}>
                                    {props.service.link}
                                </span>
                            </div>
                            <div className="card-top-vendor-right">
                                {props.service.ratings.length > 0 &&
                                    <RatingComponent rating={props.service.ratings[0]} />}
                                <p className="font-xs color-gray-500 mt-10">
                                    {props.service.categories.map(c => (
                                        <span key={c.id}>{c.name}</span>))}</p>
                            </div>

                        </div>
                        <div className="card-bottom-vendor d-flex flex-column justify-content-between"
                            style={{ height: 250 }}>
                            <p className="font-sm color-gray-500 mb-10 ">
                                {props.service.description.slice(0, 200) + "..."}
                            </p>
                            <div>
                                <p className="font-xs color-blue mb-10 ">
                                    {props.service.tags.map(t => t.name).join(", ")}
                                </p>
                                <p className="font-xs color-blue mb-10 ">
                                    {props.service.certificates.map((c, i) => (
                                        <div key={i} className="d-inline-block">
                                            <ISOIconComponent cert={c} idx={i} />
                                        </div>
                                    ))}
                                </p>

                            </div>

                        </div>
                    </div>
                </Link>
            </div> */}
            <div className="catalog-item">
                <Link to={`/catalog/${props.service.id}`}>
                    <div className="catalog-item-card">
                        <CatalogItemLogo logo={props.service.logo} />
                        <div className="catalog-item-card-description">
                            <CatalogItemHeader name={props.service.name} />
                            <CatalogItemSubHeader categories={props.service.categories} ratings={props.service.ratings} />
                            <div className="divider"></div>
                            <CatalogItemDescription
                                certificates={props.service.certificates}
                                description={props.service.description}
                                tags={props.service.tags} />
                        </div>
                    </div>
                </Link >
            </div >


        </>
    );
};

export default CatalogItem;