import React, { useEffect } from "react";
import { FlagCard } from "./components/FlagCard";
import AUflag from "../../assets/imgs/template/AUFlag.png"
import USflag from "../../assets/imgs/template/USFlag.png"
import UKflag from "../../assets/imgs/template/UKFlag.png"
import map from "../../assets/imgs/template/map-desktop.svg"
import "./home.scss";
import { useToggleHeader } from "../../components/hooks/useToggleHeader";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useToggleFooter } from "../../components/hooks/useToggleFooter";

export const domainLinks = {
  world: process.env.REACT_APP_WORLD_LOCAL_LINK || "https://hainu.world/",
  uk: process.env.REACT_APP_UK_LOCAL_LINK || "https://hainu.uk/catalog",
}

type Props = {}
const HomeView = (props: Props) => {

  const { hide } = useToggleHeader()
  const { fix, unFix } = useToggleFooter()
  useEffect(() => {
    hide()
    fix()
    return () => {
      unFix()
    }
  }, [])

  const handleClick = (link: string) => () => {
    window.location.href = link
  }


  return (
    <>
      <div className="container home-main">
        <div className="map">
          <img src={map} alt="map" />
          <div className="map-text">
            Choose your country
          </div>
        </div>
        <div className="flags">
          <FlagCard onClick={handleClick(domainLinks.uk)} flag={UKflag} countryName="United Kingdom" isInDevelopment={false} key={"UK"} link="" additionalClass="UK" />
          <FlagCard onClick={handleClick(domainLinks.world)} flag={USflag} countryName="United States" isInDevelopment={true} key={"US"} link="" additionalClass="US" />
          <FlagCard onClick={handleClick(domainLinks.world)} flag={AUflag} countryName="Australia" isInDevelopment={true} key={"AU"} link="" additionalClass="AU" />
        </div>
      </div>
    </>
  );
};

export default HomeView;