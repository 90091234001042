import React from 'react';
import { Service } from "../../../api/types";
import RatingComponent from "../../../components/RatingComponent";
import { Link } from "react-router-dom";
import ISOIconComponent from "../../../components/ISOIconComponent";
import { ServiceInfoHeader } from './ServiceInfoHeader';

type Props = {
    service: Service
}
const ServiceHeader = ({ service }: Props) => {
    const defaultCatImage = "/assets/imgs/page/vendor/featured.png";
    let category = service.categories[0];
    let catImage = category.image ? category.image : defaultCatImage;

    return (
        <>
            <section className="section-box shop-template mt-30">
                <div className="container">
                    <div className="d-flex box-banner-vendor">
                        <div className="vendor-left">
                            <ServiceInfoHeader service={service} />
                            {/* <div className="banner-vendor">

                                <img src={catImage} alt="Category Image" style={{
                                    height: 200,
                                    objectFit: "none",
                                    width: "100%",
                                }}
                                />

                                <div className="d-flex box-info-vendor">
                                    <div className="avarta">
                                        <img className="mb-5" src={service.logo} alt="Logo" />
                                        <a className="btn btn-buy font-xs" href={service.link}>Company Site</a>
                                    </div>
                                    <div className="info-vendor">
                                        <h4 className="mb-5">{service.name}</h4> */}
                            {/*<span className="font-xs color-gray-500 mr-20">Added this year</span><br/>*/}
                            {/*<span className="font-xs color-gray-500 mr-20">{service.tags.map(c => c.name).join(", ")}</span>*/}
                            {/* <div className="rating d-inline-block">
                                            {service.ratings.length > 0 &&
                                                <RatingComponent rating={service.ratings[0]} link={service.link}/>}
                                        </div>
                                    </div>
                                    <div className="vendor-contact">
                                        <div className="row">
                                            <div className="col-xl-7 col-lg-12">
                                                <div className="d-flex flex-column">
                                                    <div
                                                        className="d-inline-block font-md color-gray-500 location mb-10">
                                                        {service.countries.map(c=>c.name).join(", ")}
                                                    </div>
                                                    <div className="d-inline-block font-md color-gray-500 mb-10">
                                                        {service.certificates.map(c => (
                                                            <ISOIconComponent cert={c} idx={c.id}/>))}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-5 col-lg-12">
                                                <div className="d-inline-block font-md color-gray-500">
                                                    {service.categories.map(t => t.name).join(", ")}
                                                </div>
                                                <br/>
                                                <span className="font-xs color-gray-500 mr-20">{service.tags.map(c => c.name).join(", ")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        {/* <div className="vendor-right">
                            <div className="box-featured-product">
                                {service.features.map(f => (
                                    <div className="item-featured">
                                        <div className="featured-icon">
                                            <img src={f.icon} alt="" height={40} width={40} /></div>
                                        <div className="featured-info"><span className="font-sm-bold color-gray-1000">{f.title}</span>
                                            <p className="font-sm color-gray-500 font-medium">{f.description}</p>
                                        </div>
                                    </div>
                                ))} */}
                        {/*<div className="item-featured">*/}
                        {/*    <div className="featured-icon">*/}
                        {/*        <img src="/assets/imgs/page/product/payment.svg" alt="Ecom"/></div>*/}
                        {/*    <div className="featured-info"><span className="font-sm-bold color-gray-1000">Secure connection</span>*/}
                        {/*        <p className="font-sm color-gray-500 font-medium">SSL certificates support</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="item-featured">*/}
                        {/*    <div className="featured-icon">*/}
                        {/*        <img src="/assets/imgs/page/product/support.svg" alt="Ecom"/></div>*/}
                        {/*    <div className="featured-info"><span className="font-sm-bold color-gray-1000">Support 24/7</span>*/}
                        {/*        <p className="font-sm color-gray-500 font-medium">Shop with an expert</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="item-featured">*/}
                        {/*    <div className="featured-icon">*/}
                        {/*        <img src="/assets/imgs/page/product/return.svg" alt="Ecom"/></div>*/}
                        {/*    <div className="featured-info"><span*/}
                        {/*        className="font-sm-bold color-gray-1000">Return &amp; Refund</span>*/}
                        {/*        <p className="font-sm color-gray-500 font-medium">Free return over $200</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServiceHeader;