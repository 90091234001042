
import React, { useState, useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import "../views/ServiceView/styles/swiperStyles.scss"
import 'swiper/css/bundle';
import { ReviewCard } from '../views/ServiceView/components/ReviewCard';

export const CustomCarousel = ({ reviews, showMoreReviews }: any) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMobileState = () => setIsMobile(window.innerWidth < 460);
    window.addEventListener('resize', updateMobileState);
    updateMobileState(); // Set initial state
    return () => window.removeEventListener('resize', updateMobileState);
  }, []);

  return (
    <>
      {isMobile ?
        <>
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            {reviews &&
              reviews.results.slice(reviews.results.length - 5 || 0, showMoreReviews ? reviews.results.length : reviews.results.length - 1).map((review: any, index: any) => (
                <SwiperSlide>
                  <ReviewCard review={review} key={index} />
                </SwiperSlide>
              ))}
          </Swiper>
        </>
        :
        <>
          {reviews &&
            reviews.results
              .slice(0, showMoreReviews ? reviews.results.length : 3)
              .map((review: any, index: any) => (
                <ReviewCard review={review} key={index} />
              ))}
        </>}
    </>
  );
};