import { useCallback } from "react"

export const useToggleHeader = () => {

  const handleHide = useCallback(() => {
    const header = document.querySelector("header.header")
    if (!header) {
      return
    }
    header?.classList.add("hidden")
  }, [])
  const handleShow = useCallback(() => {
    const header = document.querySelector("header.header")
    if (!header) {
      return
    }
    header?.classList.remove("hidden")
  }, [])

  return {
    hide: handleHide,
    show: handleShow
  }
}