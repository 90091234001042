import { useCallback } from "react"

export const useToggleFooter = () => {

  const handleHide = useCallback(() => {
    const footer = document.querySelector("div.footer")
    if (!footer) {
      return
    }
    footer?.classList.add("hidden")
  }, [])
  const handleShow = useCallback(() => {
    const footer = document.querySelector("div.footer")
    if (!footer) {
      return
    }
    footer?.classList.remove("hidden")
  }, [])

  const handleFix = () => {
    const footer = document.querySelector("div.footer")
    if (!footer) {
      return
    }
    footer?.classList.add("fixed")
  }

  const handleUnFix = () => {
    const footer = document.querySelector("div.footer")
    if (!footer) {
      return
    }
    footer?.classList.remove("fixed")
  }

  return {
    hide: handleHide,
    show: handleShow,
    unFix: handleUnFix,
    fix: handleFix,
  }
}