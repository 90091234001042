import React from 'react'
import ISOIconComponent from '../../../../../components/ISOIconComponent'
import { BriefServiceTag, Certificates } from '../../../../../api/types'

type CatalogItemDescriptionProps = {
  tags: BriefServiceTag[],
  description: string,
  certificates: Certificates[],
}

export const CatalogItemDescription = ({ description, tags, certificates }: CatalogItemDescriptionProps) => {
  return (
    <div className="description-block">
      <p className="description-block-text-main">
        {description.slice(0, 250) + "..."}
      </p>
      <div className='description-block-bottom'>
        <div className="description-block-text-secondary">
          {tags.map(t => {
            return (
              <div className="text">
                {t.name}
              </div>)
          })}

        </div>
        <p className="description-block-certificates">
          {certificates.map((c, i) => (
            <div key={i} className="certificate">
              <ISOIconComponent cert={c} idx={i} />
            </div>
          ))}
        </p>

      </div>

    </div>
  )
}
