import { Service } from "../api/types";
import RatingComponent from "./RatingComponent";
import React from "react";
import { Link } from "react-router-dom";
import "../views/ServiceView/styles/serviceCard.scss"

type Props = {
    service: Service
}


const ServiceCard = (props: Props) => {
    return (
        <>
            <div className="card-main">
                <Link to={`/catalog/${props.service.id}`}>
                    <div className="card-vendor">
                        <div className="card-top-vendor" style={{ border: "none" }}>
                            <div className="card-top-vendor-left" >
                                <img src={props.service.logo} alt="Ecom" />
                                <br />
                                <span className="color-brand-3 font-sm-bold">
                                    {props.service.name}
                                </span>
                                <br />
                                <span className="font-xs color-gray-500 mb-10" style={{ whiteSpace: "nowrap" }}>
                                    {/*{props.service.link}*/}
                                </span>
                            </div>
                            <div className="card-top-vendor-right">
                                {props.service.ratings.length > 0 &&
                                    <RatingComponent rating={props.service.ratings[0]} />}
                                <p className="font-xs color-gray-500 mt-10">
                                    {props.service.categories.map(c => (
                                        <span key={c.id}>{c.name}</span>))}</p>
                            </div>

                        </div>
                        <div className="card-bottom-vendor">
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default ServiceCard;