import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getClient } from "../api/client";
import AsyncSelect from 'react-select/async';
import { Service } from "../api/types";
import "./header.scss"
import "./reactSelect.scss"
import { CSSObjectWithLabel, StylesConfig } from 'react-select';
import logo from "../assets/imgs/template/desktopLogo.svg"
import mobileLogo from "../assets/imgs/template/MobileLogo.svg"

import { SearchIcon } from './searchIcon/SearchIcon';
import { NavBarRight } from './NavBarRight';
import { domainLinks } from '../views/HomeView/HomeView';

export type Options = {
    value: string;
    logo: string;
    label: string;
    color: string;
}

const MenuItem = (props: { title: string, address: string, onClick: () => void, isActive: boolean }) => {
    const style = {
        color: props.isActive ? '#36BEF9' : '#425A8B',
    };
    return (
        <NavLink onClick={props.onClick} target={props.title === "Blog" ? "blank" : ""} to={props.address} style={style}><li>{props.title} </li></NavLink>
    )
};

// const customAsyncSelectStyles = {
//     clearIndicator: (provided: CSSObjectWithLabel, state: any) => ({
//         ...provided,

//     }),
//     container: (provided: CSSObjectWithLabel, state: any) => ({
//         ...provided,
//         width: state.isFocused ? "500px" : "220px",
//         border: 0,
//     }),
//     placeholder: (provided: CSSObjectWithLabel) => ({
//         ...provided,
//         color: "#B1B1B6",
//     }),
//     control: (provided: CSSObjectWithLabel) => ({
//         ...provided,
//         borderRadius: '18px',
//         border: 0,
//         paddingLeft: "30px",
//     }),

//     menuList: (provided: CSSObjectWithLabel) => ({
//         ...provided,

//     }),
//     option: (defaultStyles: CSSObjectWithLabel, state: any) => ({
//         ...defaultStyles,
//         color: state.isSelected ? "#212529" : "red",
//         backgroundColor: state.isSelected ? "red" : "#212529",
//     }),
//     singleValue: (defaultStyles: CSSObjectWithLabel) => ({ ...defaultStyles, color: "#fff" }),
// }

const Option = (props: any) => {
    const {
        children,
        className,
        cx,
        getStyles,
        isDisabled,
        isFocused,
        isSelected,
        innerRef,
        innerProps,
    } = props;
    return (
        <div className={"service-search-item " + getStyles('option', props) + " dropdown-option-custom "} {...props.innerProps}>
            <div className="img-box"  >
                <img src={props.data.logo} alt={props.data.label} />
            </div>
            <div className="text">
                {props.data.label}
                {/*{`${data.firstName} - ${data.lastName}`}*/}
            </div>

        </div>
    )
}

type Props = {}
const Header = (props: Props) => {
    const menu = [
        ["Catalog", "/catalog"],
        ["Blog", "https://medium.com/@HainuHealthcare"],
        ["About", "/about"],
    ];

    let { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false)
    const [dropDownOptions, setDropDownOptions] = useState<Options[] | []>([])

    const handleBurgerOpen = () => setOpen(true);
    const handleBurgerClose = () => setOpen(false);
    const handleDropDownOpen = () => setIsDropDownOpen(true)
    const handleDropDownClose = (e: any) => {
        e.preventDefault()
        console.log(1)
        setIsDropDownOpen(false)
    }


    type ServiceOption = {
        readonly value: string;
        readonly logo: string;
        readonly label: string;
        readonly color: string;
        readonly isFixed?: boolean;
        readonly isDisabled?: boolean;
    }


    const loadOptions = (
        inputValue: string,
        callback: (options: ServiceOption[]) => void
    ) => {
        getClient().catalog.services({ name: inputValue }).then((res) => {
            // console.log('res             = ', res            );
            // res.results
            let a = res.results.map((service: Service) => ({
                value: service.id.toString(),
                logo: service.logo,
                label: service.name,
                color: '#00B8D9'
            }));
            callback(a);
        });

    };

    const onServiceSelect = (e: any) => {
        // // const navigate = useNavigate();
        // navigate(`/catalog/${e.value}`);
        window.location.replace(`/catalog/${e.value}`);

        console.log('e = ', e);
    };

    useEffect(() => {
        getClient().catalog.services().then((res) => {
            // console.log('res             = ', res            );
            // res.results

            let a = res.results.map((service: Service) => ({
                value: service.id.toString(),
                logo: service.logo,
                label: service.name,
                color: '#00B8D9'
            }));
            setDropDownOptions(a)
        })
    }, [])

    const handleRedirect = (e: any) => {
        e.preventDefault()
        window.location.href = domainLinks.world
    }

    useEffect(() => {
        if (open || isDropDownOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [open, isDropDownOpen]);

    return (
        <div className='header-box'>
            {open && <div onClick={handleBurgerClose} className={"overlay"}></div>}
            {isDropDownOpen && <div onClick={handleDropDownClose} className={"overlay-blurred"}></div>}
            {/* <div className="topbar">
                <div className="container-topbar">
                    <div className="menu-topbar-left d-none d-xl-block">
                        <ul className="nav-small">
                            <li><a className="font-xs" href="page-careers.html">Partnership</a></li>
                            <li><a className="font-xs" href="page-register.html">Submit new service</a></li>
                        </ul>
                    </div>
                    <div className="info-topbar text-center d-none d-xl-block">
                        <span className="font-xs color-brand-3">Noticed some errors? </span>
                        <span className="font-sm-bold color-success">Let us know!</span>
                    </div>
                    <div className="menu-topbar-right">
                        <div className="dropdown dropdown-language">
                            <button className="btn dropdown-toggle" id="dropdownPage" type="button"
                                data-bs-toggle="dropdown" aria-expanded="true" data-bs-display="static"><span
                                    className="dropdown-right font-xs color-brand-3">
                                    <img src="/assets/imgs/template/en.svg" alt="Ecom" /> English</span>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownPage"
                                data-bs-popper="static">
                                <li><a className="dropdown-item" href="#">
                                    <img src="/assets/imgs/template/flag-en.svg" alt="Eng" /> English</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
            <header className="header sticky-bar">
                {open && (
                    <div className={"burger-menu"}>
                        <div className="burger-menu-container">

                            <ul className={"burger-menu_list"}>
                                {
                                    menu.map(([title, address]: string[], index) => (
                                        <MenuItem onClick={handleBurgerClose} key={title} title={title} address={address} isActive={false} />))
                                }
                            </ul>
                            <div className="nav-bar-container font-sm">
                                <NavBarRight />
                            </div>
                        </div>
                    </div>
                )}

                <div className="container">
                    <div className="main-header">
                        <div className="header-left">
                            <div className={"header-logo " + (isDropDownOpen ? "mobile-invisible" : "")}>
                                <a className="d-flex desktop-logo" onClick={handleRedirect} >
                                    <img alt="Ecom" src={logo} /></a>
                                <a className="mobile-logo" onClick={handleRedirect}  >
                                    <img alt="Ecom" src={mobileLogo} /></a>
                            </div>
                            <div className="header-search">
                                <div className="">
                                    {/*<form className="form-search" method="post" action="#">*/}
                                    <AsyncSelect
                                        unstyled
                                        onFocus={handleDropDownOpen}
                                        cacheOptions loadOptions={loadOptions} defaultOptions
                                        classNamePrefix="react-select"
                                        className="react-select-container"
                                        onChange={onServiceSelect} placeholder={"Search company"}
                                        // styles={customAsyncSelectStyles} 
                                        components={{
                                            DropdownIndicator: () => <SearchIcon />,
                                            IndicatorSeparator: () => null,
                                            Option
                                        }} />
                                    {/*<div className="box-keysearch">*/}
                                    {/*    <input className="form-control font-xs" type="text" value=""*/}
                                    {/*           placeholder="Search for items" onChange={e => console.log}/>*/}

                                    {/*</div>*/}
                                    {/*</form>*/}
                                </div>
                            </div>
                            <div className="header-nav">
                                <nav className="nav-main-menu d-none d-xl-block">
                                    <ul className="main-menu main-menu-style">
                                        {
                                            menu.map(([title, address]: string[], index) => {
                                                return <MenuItem onClick={handleBurgerClose} key={title} title={title} address={address} isActive={pathname === address} />
                                            })
                                        }
                                    </ul>
                                </nav>

                                <div onClick={handleBurgerOpen} className={"burger-icon burger-icon-white " + (isDropDownOpen ? "mobile-invisible" : "")}><span className="burger-icon-top"></span><span
                                    className={"burger-icon-mid "}></span><span className="burger-icon-bottom"></span>
                                </div>
                            </div>


                            <div className={"drop-down-cancel " + (isDropDownOpen ? "mobile-visible" : "")}>
                                <button onClick={handleDropDownClose} >Cancel</button>
                            </div>

                            {/* <div className="header-shop">
                            </div> */}
                        </div>
                        <NavBarRight />
                    </div>
                </div>
            </header>
        </div >
    );
};

export default Header;