import React from 'react';
import { Link, NavLink } from "react-router-dom";
import client from "../../api/client";
import logo from "../../assets/imgs/template/footer-logo.svg"
import "./footer.scss"

type Props = {}
const Footer = (props: Props) => {
    return (
        <div className={"footer"}>
            <div className="container">
                <div className="footer-block">

                    <div className="footer-logo">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="footer-copyright">
                        Copyright © 2024 Hainu. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
{/* <div className="footer-2">
    <div className="footer-bottom-1">
        <div className="container">
            <div className="footer-2-top mb-20"><a href="index.html">
                <img alt="Hainu" width={50} height={50} src="/assets/imgs/template/logo.svg" /></a>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="footer-bottom mt-20">
            <div className="row">
                <div className="col-lg-6 col-md-12 text-center text-lg-start"><span
                    className="color-gray-900 font-sm">Copyright © 2023 Hainu. All rights reserved.</span>
                </div>
                <div className="col-lg-6 col-md-12 text-center text-lg-end">
                    <ul className="menu-bottom">
                        {menu.map((item, index) => (
                            <li key={index}>
                                <Link target={item[0] === "Blog" ? "blank" : ""} to={item[1]} className="font-sm color-gray-900" key={item[1]}>
                                    {item[0]}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> */}