import geoIcon from "../assets/imgs/template/geolocation.svg"
import "./navBarRight.css"


export const NavBarRight = () => {
  return (
    <div className="menu-topbar-right">
      <div className="dropdown dropdown-language">
        <button className="btn dropdown-toggle" id="dropdownPage" type="button"
          data-bs-toggle="dropdown" aria-expanded="true" data-bs-display="static">
          <div
            className="dropdown-right font-xs color-brand-3">
            <img src={geoIcon} alt="Ecom" />
            <div className="text">
              United Kingdom
            </div>
          </div>
        </button>
        <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="dropdownPage"
          data-bs-popper="static">
          <li><a className="dropdown-item" href="#">
            <img src="/assets/imgs/template/flag-en.svg" alt="Eng" /> United Kingdom</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
