import React from 'react';
import { Link, Route, Routes, Outlet, useParams } from "react-router-dom";
import client from "../../api/client";
import ServiceHeader from "./components/ServiceHeader";
import { Service } from "../../api/types";
import TrustBox from "./components/TrustBox";

export type ServiceContextType = { service: Service };

type Props = {}
const ServiceView = (props: Props) => {
    let { serviceId } = useParams();
    if (!serviceId) {
        return <div>Post not found</div>
    }

    const [{ data, loading, error }, refetch] = client.catalog.service(serviceId);
    if (loading || !data) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error loading...</div>
    }

    return (
        <>
            <div className="section-box">
                <div className="breadcrumbs-div">
                    <div className="container">
                        <ul className="breadcrumb">
                            <li><Link to={"/catalog"} className="font-xs color-gray-1000">Catalog</Link></li>
                            <li><span className="font-xs color-gray-1000">{data.name}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <ServiceHeader service={data} />
            <Outlet context={{ service: data }} />

            {/*<div id="kt_app_toolbar" className="app-toolbar  pt-5 pt-lg-10 ">*/}
            {/*    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-wrap">*/}
            {/*        <div className="app-toolbar-wrapper d-flex flex-stack flex-wrap gap-4 w-100">*/}
            {/*            <div className="page-title d-flex flex-column justify-content-center gap-1 me-3">*/}
            {/*                <h1 className="page-heading d-flex flex-column justify-content-center text-dark fw-bold fs-3 m-0">*/}
            {/*                    Service Page*/}
            {/*                </h1>*/}
            {/*                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">*/}
            {/*                    <li className="breadcrumb-item text-muted">*/}
            {/*                        <Link to={"/catalog/"} className="text-muted text-hover-primary">*/}
            {/*                            Catalog </Link>*/}
            {/*                    </li>*/}
            {/*                    <li className="breadcrumb-item">*/}
            {/*                        <span className="bullet bg-gray-400 w-5px h-2px"></span>*/}
            {/*                    </li>*/}
            {/*                    <li className="breadcrumb-item text-muted">{data.name}</li>*/}
            {/*                </ul>*/}

            {/*            </div>*/}

            {/*<div className="d-flex align-items-center gap-2 gap-lg-3">*/}
            {/*    <a href="#"*/}
            {/*       className="btn btn-flex btn-outline btn-color-gray-700 btn-active-color-primary bg-body h-40px fs-7 fw-bold"*/}
            {/*       data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">*/}
            {/*        Add Member*/}
            {/*    </a>*/}

            {/*</div>*/}

            {/*        </div>*/}
            {/*    </div>*/}

            {/*</div>*/}


        </>
    );
};

export default ServiceView;