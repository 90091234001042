import React from 'react';
import {Rating} from "../api/types";

type Props = {
    rating: Rating,
    link?: string,
}
const RatingComponent = ({rating, link}: Props) => {
    let trust = "";

    if (link) {
        trust = "https://www.trustpilot.com/review/" + link.replace("https://", "");
    }
    let stars = [];
    for (let i = 0; i < 5; i++) {
        const img = Math.round(rating.score) > i ? "star.svg" : "star-gray.svg";
        const link = "/assets/imgs/template/icons/" + img;
        stars.push(<img key={i} src={link} alt="star"/>)
    }

    // if (rating.score == 0) {
    //     return <></>
    // }

    return (
        <>
            <div className="rating">
                <a href={trust}>
                    {stars.map((star, index) => (star))}
                    <span className="font-xs color-gray-500"> {rating.score}</span>
                </a>
            </div>
        </>)
};

export default RatingComponent;