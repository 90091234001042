import React, { useEffect, useState } from 'react';
import { Paginated, Service, ServiceRatingReview } from "../../../api/types";
import { useOutletContext } from "react-router-dom";
import { ServiceContextType } from "../ServiceView";
import client, { getClient } from "../../../api/client";
import ScaledRatingStars from "../../../components/ScaledRatingStars";
import RelatedServices from "./RelatedServices";
import "../service.css"
import RatingComponent from '../../../components/RatingComponent';
import { Mentions } from './Mentions';
import { TotalReviewScore } from './TotalReviewScore';
import { ReviewsSection } from './ReviewSection';
import { ScreenShots } from './ScreenShots';


type Props = {}
const ServiceInfo = (props: Props) => {
    const { service } = useOutletContext<ServiceContextType>();

    const [reviews, setReviews] = useState<Paginated<ServiceRatingReview>>();
    const [readMoreBio, setReadMoreBio] = useState(false);


    useEffect(() => {
        setReviews(undefined);
        getClient().catalog.serviceReviews(service.id).then(setReviews);
    }, [service]);

    function domainFromUrl(url: string) {
        const m = url.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/gi);
        if (m) {
            return m[0];
        }
        return "";
    }

    return (
        <>
            <section className="section-box shop-template ">
                <div className="container">
                    <RelatedServices service={service} />
                    <Mentions domainFromUrl={domainFromUrl} service={service} />
                    {(service.ratings.length > 0 && reviews) && <ReviewsSection reviews={reviews} service={service} />}
                    <ScreenShots screenshots={service.screenshots} />
                </div>

            </section>


        </>
    );
};

export default ServiceInfo;